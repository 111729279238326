import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener("error", function (e) {
  var errorText = [
    e.message,
    "URL: " + e.filename,
    "Line: " + e.lineno + ", Column: " + e.colno,
    "Stack: " + ((e.error && e.error.stack) || "(no stack trace)")
  ].join("\n");

  console.log("App Error ", errorText);

  // Example: log errors as visual output into the host page.
  // Note: you probably don't want to show such errors to users, or
  //       have the errors get indexed by Googlebot; however, it may
  //       be a useful feature while actively debugging the page.
  let DOM_ID = "rendering-debug-pre";
  if (!document.getElementById(DOM_ID)) {
    var log = document.createElement("pre");
    log.id = DOM_ID;
    log.style.whiteSpace = "pre-wrap";
    log.textContent = errorText;
    if (!document.body) document.body = document.createElement("body");
    document.body.insertBefore(log, document.body.firstChild);
  } else {
    document.getElementById(DOM_ID).textContent += "\n\n" + errorText;
  }
});
