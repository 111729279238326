import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  TextField,
  Box,
  Alert,
  Container,
  IconButton,
  MenuItem,
  Select,
  Tooltip
} from "@mui/material";

import ArchiveProgress from "./components/archiveProgress";
import ArchiveResult from "./components/archiveResult";
import AccountPlan from "./components/home/accountPlan";
import LogoText from "./components/logoText";
import RecentArchiveJobs from "./components/recentArchiveJobs";
import { arraysAreEqual } from "./helpers/arrayHelper";
import useArchive from "./hooks/useArchive";
import useCaptcha from "./hooks/useCaptcha";
import {
  doArchieve,
  getArchiveDetails,
  getTopArchieveList,
  notifyArchiveJobCompleted
} from "./services/webArchiveService";

import "./homeStyle.css";

function HomePage() {
  const [processingState, setProcessingState] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [archurl, setArchUrl] = React.useState("");

  const [id, setId] = React.useState(null);
  const [archive, setArchive] = React.useState(null);
  const [isValidUrl, setIsValidUrl] = React.useState(true);
  const [recentArchive, setRecentArchive] = React.useState([]);
  const [userSelection, setUserSelection] = React.useState("public");
  const [lastUpdated, setLastUpdated] = React.useState(null);
  const [recentLoading, setRecentLoading] = React.useState(false);
  const [canShowArchive, setCanShowArchive] = React.useState(false);
  const [hideSearchInput, setHideSearchInput] = React.useState(false);

  const {
    getCaptchElement,
    setArchiveCount,
    canAllowArchive,
    hasArchiveAllowed
  } = useCaptcha();
  const { bandedSiteInfo, isRestrictedSite } = useArchive({ url });

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (id && processingState === "LOADING") {
        getArchiveDetails(id).then(async (x) => {
          if (x.status === "completed") {
            const jbId = await notifyArchiveJobCompleted(x.jobId);
            const arch = await getArchiveDetails(jbId);
            setProcessingState("DONE");
            setArchive(arch);
          }
        });
      }
      fetchArchiveList();
    }, 10000);

    return () => clearInterval(timer);
  });

  React.useEffect(() => {
    fetchArchiveList();
  }, [processingState]); // eslint-disable-line react-hooks/exhaustive-deps

  function fetchArchiveList() {
    setRecentLoading(true);
    getTopArchieveList().then((x) => {
      if (!arraysAreEqual(x, recentArchive, "id")) {
        setRecentArchive(x);
      }
      //setRecentArchive(x);
      setLastUpdated(Date.now());
      setRecentLoading(false);
    });
  }

  function handleChange(e) {
    setUrl(e.target.value);
  }

  function onCancel() {
    setProcessingState("");
    setUrl("");
    setId("");
    setArchive(null);
    setIsValidUrl(true);
    setArchUrl("");
    setCanShowArchive(false);
    setHideSearchInput(false);
  }

  function onSubmit(e) {
    onCancel();
    setArchive(null);
    setId("");
    if (!validateHttpUrl(url)) {
      setIsValidUrl(false);
      return;
    }
    if (!hasArchiveAllowed()) {
      setArchiveCount();
      return;
    }
    setArchUrl(url);

    setIsValidUrl(true);
    setHideSearchInput(true);

    setProcessingState("LOADING");
    setArchiveCount();

    doArchieve(url).then((x) => {
      if (x) {
        setId(x);
        setUrl("");
      } else {
        setProcessingState("FAILED");
        setId(null);
      }
    });
  }

  function getProcessingText() {
    if (processingState === "FAILED") {
      return "Processing failed";
    }
    if (processingState === "DONE") {
      return "Completed";
    }
    return "Processing ";
  }

  function validateHttpUrl(url) {
    const pattern =
      /^(https?:\/\/)?(www\.)?([^\s.]+\.[^\s]{2,}|localhost)(\/[^\s]*)?$/i;

    return (
      (url.startsWith("http://") || url.startsWith("https://")) &&
      pattern.test(url)
    );
  }

  function showArchive() {
    ///archive/${params.row.shortId}/${params.row.url}`
    window.open(`/archive/${archive?.shortId}/${archive.url}`, "_blank");
  }

  function getCompletedArchiveData() {
    if (recentArchive) {
      return recentArchive.find((x) => x.jobId === archive.jobId);
    }
  }

  function handleClearClick() {
    setUrl("");
  }
  function handleUpdate(event) {
    setUserSelection(event.target.value);
  }

  return (
    <div className={"root"}>
      <Container
        className={"content"}
        sx={{ maxWidth: "100%", display: "flex", flexGrow: "1" }}
      >
        <section className="bg-image">
          <LogoText />
          {!hideSearchInput && (
            <div
              className="searchIn"
              style={{
                position: "relative"
              }}
            >
              <TextField
                autoComplete="off"
                disabled={!canAllowArchive}
                error={!isValidUrl}
                value={url}
                id="outlined-basic"
                variant="outlined"
                placeholder="Type/Paste the site URL to archive"
                className={"searchBox"}
                onChange={handleChange}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    onSubmit();
                  }
                }}
                InputProps={{
                  endAdornment: url && (
                    <IconButton onClick={handleClearClick}>
                      <ClearIcon />
                    </IconButton>
                  )
                }}
              ></TextField>
              <Box display={"flex"}>
                <Select
                  style={{ background: "white", minWidth: "97px" }}
                  id="type"
                  value={userSelection}
                  onChange={handleUpdate}
                  defaultValue="public"
                >
                  <MenuItem value="public">Public</MenuItem>
                  <MenuItem value="private" disabled>
                    Private
                  </MenuItem>
                </Select>
                <Button
                  className="searchButton"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={!canAllowArchive || isRestrictedSite()}
                >
                  Save
                </Button>
              </Box>
            </div>
          )}
          {/* <ArchiveProgress isDownloaded={processingState === "DONE"} /> */}
          {!isValidUrl && (
            <Alert severity="error">The site url is not valid....!</Alert>
          )}
          {validateHttpUrl(url) && bandedSiteInfo()}
          {getCaptchElement()}
          {["LOADING", "DONE", "FAILED"].includes(processingState) && (
            <>
              <Alert
                severity={"success"}
                color={processingState === "FAILED" ? "error" : "success"}
                icon={false}
                sx={{
                  "& .MuiAlert-message": {
                    width: "100%"
                  }
                }}
                style={{
                  marginTop: "15px",
                  width: "90%",
                  maxWidth: "630px",
                  marginBottom: "10px"
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-around"}
                    marginBottom={"5px"}
                    overflow={"hidden"}
                    width="100%"
                  >
                    <Tooltip title={archurl}>
                      <p className="multiline">
                        <b>{getProcessingText()} </b> {archurl}
                      </p>
                    </Tooltip>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"column"}
                      marginLeft={"15px"}
                    >
                      {/* {processingState === "LOADING" && (
                        <CircularProgress
                          style={{
                            color: "black",
                            height: "30px",
                            width: "30px"
                          }}
                        />
                      )} */}
                      {processingState === "DONE" && canShowArchive && (
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={showArchive}
                          style={{ minWidth: "126px" }}
                        >
                          {"Show Archive"}
                        </Button>
                      )}
                    </Box>
                  </Box>
                  {!canShowArchive && (
                    <ArchiveProgress
                      isDownloaded={processingState === "DONE"}
                      url={archurl}
                      onCompleteCallback={() => {
                        setCanShowArchive(true);
                      }}
                    />
                  )}

                  {processingState === "DONE" && canShowArchive && (
                    <ArchiveResult
                      archive={getCompletedArchiveData()}
                      siteLink={`/archivedsite/${archive?.jobId}`}
                    />
                  )}
                  {processingState === "DONE" && canShowArchive && (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={onCancel}
                      style={{ marginTop: "10px" }}
                    >
                      Archive another URL
                    </Button>
                  )}
                  {processingState !== "DONE" && (
                    <Button
                      size="small"
                      onClick={onCancel}
                      style={{ marginTop: "10px" }}
                    >
                      {processingState === "DONE" ? "done" : "cancel"}
                    </Button>
                  )}
                </Box>
              </Alert>
            </>
          )}
        </section>
        <AccountPlan />

        <RecentArchiveJobs
          archive={recentArchive}
          lastUpdated={lastUpdated}
          showLoading={recentLoading}
        />
      </Container>
    </div>
  );
}

export default HomePage;
