import React from "react";
import { Container } from "@mui/material";

const TermsAndConditionView = () => {
  return (
    <Container>
      <h1>Terms of Service</h1>
      <p>
        Welcome to webarchive.io! Before you start using our website, please
        read and understand the following terms of service ("TOS"). By using
        webarchive.io, you acknowledge that you have read, understood and agree
        to be bound by these TOS. If you do not agree with any part of these
        TOS, please refrain from using our website.
      </p>

      <h2>1. Website content and user-generated content</h2>
      <p>
        Webarchive.io is an online service that allows users to archive web
        pages for future reference. We do not upload any content to our website
        and do not take responsibility for any content uploaded by our users. By
        using our website, you agree not to upload any content that is
        offensive, defamatory, obscene, hateful, or otherwise objectionable.
      </p>

      <h2>2. User accounts and security</h2>
      <p>
        To use certain features on webarchive.io, you may need to create an
        account. You are responsible for maintaining the confidentiality of your
        account and password and for restricting access to your computer, and
        you agree to accept responsibility for all activities that occur under
        your account or password. We reserve the right to terminate your account
        or restrict access to our website at any time and for any reason.
      </p>

      <h2>3. Copyright</h2>
      <p>
        Webarchive.io and its entire contents, features and functionality are
        owned by webarchive.io and/or its licensors and are protected by United
        States and international copyright, trademark, patent, trade secret and
        other intellectual property or proprietary rights laws. You may not use
        any of our content without our prior written consent.
      </p>

      <h2>4. Disclaimer of warranties</h2>
      <p>
        Webarchive.io provides the website and its contents "as is" and "as
        available". We make no representations or warranties of any kind,
        express or implied, regarding the operation of the website, the
        information, content, materials, or products included on the website. To
        the fullest extent permissible under applicable law, we disclaim all
        warranties, express or implied, including but not limited to implied
        warranties of merchantability and fitness for a particular purpose.
      </p>

      <h2>5. Limitation of liability</h2>
      <p>
        Webarchive.io shall not be liable for any damages of any kind arising
        from the use of the website, including but not limited to direct,
        indirect, incidental, consequential, and punitive damages. We shall not
        be liable for any damages or injury caused by any failure of
        performance, error, omission, interruption, deletion, defect, delay in
        operation or transmission, computer virus, communication line failure,
        theft or destruction or unauthorized access to, alteration of, or use of
        record, whether for breach of contract, tortious behavior, negligence,
        or under any other cause of action.
      </p>
    </Container>
  );
};

export default TermsAndConditionView;
