import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
//import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, Container, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridActionsCellItem,
  // GridToolbarContainer,
  GridRowModes
} from "@mui/x-data-grid";

import {
  addBlackListSites,
  getBlackListSites,
  deleteBlackListSites
} from "../services/adminService";

const BlackListView = () => {
  const [rows, setRows] = useState(undefined);
  React.useEffect(() => {
    if (!rows) {
      loadSites();
    }
  });

  function loadSites() {
    getBlackListSites().then((x) => {
      setRows(x || []);
    });
  }
  return (
    <Container style={{ marginTop: "20px" }}>
      {/* <EditableTable /> */}
      <BlackListDataGrid data={rows} onReloadRequest={loadSites} />
    </Container>
  );
};
export default BlackListView;

const useStyles = makeStyles({
  stripedRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5"
    }
  }
});

const BlackListDataGrid = ({ data, onReloadRequest }) => {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [newRowName, setNewRowName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const rows = data || [];
  const columns = [
    {
      field: "site",
      headerName: "Site",
      minWidth: 300,
      flex: 1,
      editable: true
    },
    {
      headerName: "Action",
      width: 150,
      field: "actions",
      type: "actions",
      getActions: getAction
    }
  ];

  // const handleEditClick = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.Edit, fieldToFocus: "site" }
  //   });
  // };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setIsLoading(true);
    deleteBlackListSites(id).then(() => {
      onReloadRequest && onReloadRequest();
      setIsLoading(false);
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
  };

  function onAddNewSite() {
    if (newRowName.trim() === "") return;
    setIsLoading(true);

    addBlackListSites(newRowName).then(() => {
      onReloadRequest && onReloadRequest();
      setNewRowName("");
      setIsLoading(false);
    });
  }

  function getAction({ id }) {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: "primary.main"
          }}
          onClick={handleSaveClick(id)}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="inherit"
        />
      ];
    }
    return [
      // <GridActionsCellItem
      //   icon={<EditIcon />}
      //   label="Edit"
      //   className="textPrimary"
      //   onClick={handleEditClick(id)}
      //   color="inherit"
      // />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(id)}
        color="inherit"
      />
    ];
  }

  function handleChange(e) {
    setNewRowName(e.target.value);
  }

  function getToolBar() {
    return (
      <Container
        style={{ position: "relative" }}
        className={"blstTxtContainer"}
      >
        <TextField
          id={"siteText"}
          autoComplete="off"
          label="Add banded sites here"
          variant="standard"
          size="small"
          style={{ minWidth: "50%" }}
          value={newRowName}
          onChange={handleChange}
        />
        <Button color="primary" startIcon={<AddIcon />} onClick={onAddNewSite}>
          Add site
        </Button>
        {isLoading && (
          <CircularProgress
            size="1.5rem"
            style={{ right: "10px", position: "absolute" }}
          />
        )}
      </Container>
    );
  }
  return (
    <>
      {getToolBar()}
      <DataGrid
        rows={rows}
        rowModesModel={rowModesModel}
        columns={columns}
        // slots={{
        //   toolbar: getToolBar
        // }}
        rowSelection={false}
        responsive={"vertical"}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } }
        }}
        pageSizeOptions={[25, 50, 100]}
        getRowClassName={() => classes.stripedRow}
      />
    </>
  );
};
