import React from "react";
import { Container } from "@mui/material";

import LogoText from "../components/logoText";

const AboutView = () => {
  return (
    <Container style={{ maxWidth: "800px" }}>
      <LogoText />

      <h1>Welcome to our state-of-the-art website archiving service!</h1>
      <p>
        Our platform is the fastest and most reliable archiving solution on the
        market. With our modern technology and innovative approach, we can
        archive your website in record time and store it securely on S3
        certified host.
      </p>
      <p>
        What sets us apart from our competitors is that we offer full support
        for archiving YouTube and Twitter content, in addition to standard web
        content. Our cutting-edge archiving tool allows you to easily capture
        and preserve multimedia content, ensuring that you never lose access to
        your favorite videos, tweets, and other media.
      </p>
      <p>
        Our service offers unparalleled speed, accuracy, and efficiency, making
        us the preferred choice for businesses and individuals who demand the
        best. Whether you need to archive a single page or an entire website, we
        have the tools and expertise to get the job done quickly and
        effectively. Try our service today and experience the difference for
        yourself!
      </p>
    </Container>
  );
};

export default AboutView;
