/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/joy/LinearProgress";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { getDomainFromUrl } from "../helpers/urlHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white !important",
    height: "6px"
  }
}));

const ArchiveProgress = ({ isDownloaded, url, onCompleteCallback }) => {
  const [progress, setProgress] = useState(10);
  const [progressState, setProgressState] = useState("START");

  const [text, setText] = useState(
    `Establishing connection with host ${getDomainFromUrl(url)}`
  );

  const connectedCallBack = React.useCallback(() => setConnectedState());
  const classes = useStyles();

  useEffect(() => {
    window.progress = progress;
    if (progressState !== "DONE" && progressState !== "COMPILE") {
      // Step 1: Establishing connection with host (2 seconds)
      const connectionTimer = setTimeout(() => {
        connectedCallBack();
        clearTimeout(connectionTimer);
      }, 2000);

      // Step 2: Downloading content (based on isDownloaded flag)
      const downloadTimer = setInterval(() => {
        if (progress >= 20) doProcess(downloadTimer);
      }, 1000);

      return () => {
        clearTimeout(connectionTimer);
        clearInterval(downloadTimer);
      };
    }
  });
  function doProcess(downloadTimer) {
    if (progressState === "DONE" || progressState === "COMPILE") {
      return;
    }
    if (
      progressState !== "DONE" &&
      progressState !== "COMPILE" &&
      window.progress < 100
    ) {
      window.progress += 1;

      setProgressState("DOWNLOADING");
      setProgress(window.progress + 1);
    }
    //It's taking a bit longer than expected, but we'll get there as fast as we can

    setText("Downloading content");

    if (isDownloaded) {
      clearInterval(downloadTimer);

      setProgressState("COMPILE");
      setProgress(100);

      setText("Compiling file");
      setTimeout(() => {
        setText("Done");
        setProgressState("DONE");
        setProgress(100);
        onCompleteCallback && onCompleteCallback();
      }, 2000);
    }

    if (progress === 100 && progressState === "DOWNLOADING") {
      setText("It's taking a bit longer than expected...!");
    }
  }

  function setConnectedState() {
    if (progressState === "START") {
      setText(`Connected with host ${getDomainFromUrl(url)}`);
      setProgress(progress + 10);
      setProgressState("CONNECTED");
    }
  }

  return (
    <Box
      style={{ width: "100%", maxWidth: "400px", margin: "0 auto" }}
      textAlign={"center"}
    >
      <LinearProgress
        className={classes.root}
        variant={
          progress === 100 && progressState === "DOWNLOADING"
            ? "indeterminate"
            : "soft"
        }
        size="sm"
        color="primary"
        determinate={!(progress === 100 && progressState === "DOWNLOADING")}
        value={progress}
      />
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
};

export default ArchiveProgress;
