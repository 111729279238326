import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

function valueLabelFormat(value) {
  if (value === 0 || value === 1) {
    return "Max";
  }

  const units = ["KB", "MB", "GB", "TB"];

  let unitIndex = 0;
  let scaledValue = value;

  while (scaledValue >= 1024 && unitIndex < units.length - 1) {
    unitIndex += 1;
    scaledValue /= 1024;
  }

  return `${scaledValue} ${units[unitIndex]}`;
}

function calculateValue(value) {
  return 2 ** value;
}

export default function StorageSlider({ storageValue, onChange }) {
  const [value, setValue] = React.useState(
    storageValue ? Math.log2(storageValue) : 0
  );

  React.useEffect(() => {
    if (storageValue === null) setValue(0);
  }, [storageValue]);

  const handleChange = (_, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
      onChange && onChange(calculateValue(newValue));
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography id="non-linear-slider" gutterBottom>
        Size: {valueLabelFormat(calculateValue(value))}
      </Typography>
      <Slider
        value={value}
        defaultValue={0}
        min={0}
        step={1}
        max={30}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="off"
        aria-labelledby="non-linear-slider"
      />
    </Box>
  );
}
