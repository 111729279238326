import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Select, MenuItem, Typography, Stack } from "@mui/material";

const FeedbackSelection = ({ value, onFeedbackChanged }) => {
  const [feedbackValue, setFeedbackValue] = React.useState(value || "NONE");

  React.useEffect(() => {
    if (value === null) setFeedbackValue("NONE");
  }, [value]);

  return (
    <>
      <Select
        size="small"
        value={feedbackValue}
        defaultValue={"NONE"}
        onChange={(e) => {
          setFeedbackValue(e.target.value);
          onFeedbackChanged && onFeedbackChanged(e.target.value);
        }}
        style={{ minWidth: "140px", marginBottom: "5px", marginTop: "5px" }}
      >
        <MenuItem value="NONE">
          <Stack direction="row">
            <CircleIcon
              htmlColor="#cfd8dc"
              style={{ width: "15px", height: "15px", marginRight: "5px" }}
            />
            <Typography variant="caption">None</Typography>
          </Stack>
        </MenuItem>
        <MenuItem value="OK">
          <Stack direction="row">
            <CircleIcon
              htmlColor="#4caf50"
              style={{ width: "15px", height: "15px", marginRight: "5px" }}
            />
            <Typography variant="caption">OK</Typography>
          </Stack>
        </MenuItem>
        <MenuItem value="FAULT">
          <Stack direction="row">
            <CircleIcon
              htmlColor="#f44336"
              style={{ width: "15px", height: "15px", marginRight: "5px" }}
            />
            <Typography variant="caption">Fault</Typography>
          </Stack>
        </MenuItem>
        <MenuItem value="SITE_DELAY">
          <Stack direction="row">
            <CircleIcon
              htmlColor="#ffeb3b"
              style={{ width: "15px", height: "15px", marginRight: "5px" }}
            />
            <Typography variant="caption">Site delay</Typography>
          </Stack>
        </MenuItem>
        <MenuItem value="REPORT_DEV">
          <Stack direction="row">
            <CircleIcon
              htmlColor="#01579b"
              style={{ width: "15px", height: "15px", marginRight: "5px" }}
            />
            <Typography variant="caption">Report to dev</Typography>
          </Stack>
        </MenuItem>
      </Select>
    </>
  );
};

export default FeedbackSelection;
