import { useSelector } from "react-redux";

import { doAdminAuth } from "../services/webArchiveService";

const useUserAuth = () => {
  const { isAuthenticated = false } = useSelector((state) => state.user);

  function doLogin(userName, passWord) {
    return doAdminAuth(userName, passWord).then((x) => {
      return {
        isAuthenticated: x.userId !== undefined,
        userId: x.userId,
        token: x.token
      };
    });
  }

  return { isAuthenticated, doLogin };
};

export default useUserAuth;
