import { SET_USER, SET_CAPTCHA_COUNT } from "./actionTypes";

const getArchiveItems = () => {
  try {
    return [];
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    //log error
    console.log("getArchiveItems failed ", error.message);
  }
  return [];
};

const setAdminUser = (userInfo) => {
  return {
    type: SET_USER,
    payload: userInfo
  };
};

const setCaptchaCount = (count) => {
  return {
    type: SET_CAPTCHA_COUNT,
    payload: count
  };
};

export { getArchiveItems, setAdminUser, setCaptchaCount };
