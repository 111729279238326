import React from "react";
import { Navigate } from "react-router-dom";

import useUserAuth from "../hooks/useUserAuth";

const AuthRoute = ({ element }) => {
  const { isAuthenticated } = useUserAuth();

  if (isAuthenticated) {
    return <>{element}</>;
  } else {
    return <Navigate to="/diw55ketc" />;
  }
};

export default AuthRoute;
