function getDomainFromUrl(url) {
  try {
    if (!url) return "";
    const parsedUrl = new URL(url);
    return parsedUrl.hostname.replace("www.", "");
  } catch (err) {
    console.log(err.message);
  }

  return "";
}
export { getDomainFromUrl };
