import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Grid, Paper } from "@mui/material";

import useCaptcha from "../hooks/useCaptcha";
import useUserAuth from "../hooks/useUserAuth";
import { setAdminUser } from "../store/action";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { doLogin } = useUserAuth();

  const { getCaptchElement, isVerified } = useCaptcha();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    doLogin(username, password).then((x) => {
      dispatch(setAdminUser(x));
      navigate("/");
    });
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Username"
                  variant="outlined"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={!isVerified}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Login
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                alignContent={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                {getCaptchElement(true)}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
