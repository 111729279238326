import React from "react";
import { Box } from "@mui/material";

import logo from "../static/archivelogo.svg";

const LogoText = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height="120px"
    >
      <img
        style={{
          height: "120px",
          objectFit: "contain",
          width: "95%"
        }}
        src={logo}
        alt="Logo"
      />
      {/* <Typography style={{ color: "black", fontSize: "45px" }}>
        web.archive
      </Typography> */}
    </Box>
  );
};

export default LogoText;
