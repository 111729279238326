import React from "react";

import { getArchieveList } from "../services/webArchiveService";

const useArchivePagination = () => {
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(10);
  const [totalArchiveRowCount, setTotalArchiveRowCount] = React.useState(0);
  const [archiveList, setArchiveList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [filter, setFilter] = React.useState({});
  const [sortBy, setSortBy] = React.useState({});

  // React.useEffect(() => {
  //   if (pageIndex === 0 && pageCount === 10 && archiveList.length === 0) {
  //     setPageCount(20);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useEffect(() => {
    if (pageIndex === -1) return;

    setLoading(true);

    getArchivePageData().then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 400);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageCount, filter, sortBy]);

  async function getArchivePageData() {
    const archData = await getArchieveList({
      pageIndex,
      pageCount,
      filter,
      sortBy
    });
    if (archData) {
      setTotalArchiveRowCount(archData.totalArchiveCount);
      setArchiveList(archData?.archiveList || []);
    } else {
      setArchiveList([]);
    }
  }

  return {
    archiveList,
    totalArchiveRowCount,
    pageIndex,
    pageCount,
    setPageIndex,
    setPageCount,
    setFilter,
    setSortBy,
    loading,
    hasFilter: Object.keys(filter).length > 0
  };
};

export default useArchivePagination;
