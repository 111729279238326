import { SET_CAPTCHA_COUNT, SET_USER } from "./actionTypes";

const initialState = {
  user: {
    isAuthenticated: false,
    userId: undefined,
    token: undefined
  },
  captcha_count: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload
      };
    case SET_CAPTCHA_COUNT:
      return {
        ...state,
        captcha_count: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
