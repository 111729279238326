import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Container, CssBaseline } from "@mui/material";

const NotFound = () => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div
        style={{
          marginTop: "8vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography variant="h4" gutterBottom>
          404 - Not Found
        </Typography>
        <Typography variant="subtitle1" paragraph>
          The page you are looking for might be in another castle.
        </Typography>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button variant="contained" color="primary" size="large">
            Go to Home
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default NotFound;
