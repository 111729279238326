import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import HCaptcha from "@hcaptcha/react-hcaptcha";

import { setCaptchaCount } from "../store/action";

const useCaptcha = () => {
  const LIMIT = 5;
  const dispatch = useDispatch();
  const { captcha_count } = useSelector((state) => state);

  const [count, setCount] = React.useState(captcha_count || 0);
  const [canAllowArchive, setCanAllowArchive] = React.useState(true);
  const [isVerified, setIsVerified] = React.useState(false);

  function hasArchiveAllowed() {
    return !(count + 1 >= LIMIT);
  }

  function setArchiveCount() {
    setCanAllowArchive(!(count + 1 >= LIMIT));
    setCount(count + 1);

    dispatch(setCaptchaCount(count + 1));
  }

  function clearCount() {
    setCount(0);
    setCanAllowArchive(true);
    setIsVerified(true);

    dispatch(setCaptchaCount(0));
  }

  function onToken(token, eKey) {
    if (token && eKey) {
      clearCount();
    }
  }
  function getCaptchElement(ignoreCountCheck = false) {
    return (
      <>
        {(count >= LIMIT || ignoreCountCheck) && (
          <>
            <HCaptcha
              size={"normal"}
              sitekey={"68bcbc38-df19-42a8-91b3-ba6ba3daa113"}
              onVerify={onToken}
              onExpire={() => onToken("")}
              onError={(err) => {
                onToken("");
                // showNotification({
                //   title: "Error",
                //   message: "Cannot verify captcha",
                // });
                console.error(err);
              }}
            />
          </>
        )}
      </>
    );
  }

  return {
    setArchiveCount,
    getCaptchElement,
    clearCount,
    canAllowArchive,
    isVerified,
    hasArchiveAllowed
  };
};

export default useCaptcha;
