import React from "react";
import { formatDistance, subDays, differenceInDays } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import LaunchIcon from "@mui/icons-material/Launch";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Button,
  Link,
  CircularProgress,
  Box,
  Tooltip,
  Typography,
  ClickAwayListener,
  IconButton
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import useUserAuth from "../hooks/useUserAuth";

const useStyles = makeStyles({
  stripedRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5"
    }
  }
});

const TableView = ({
  data,
  captionText,
  title,
  onDelete,
  showLoading,
  isMinimalView = false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated } = useUserAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const [tableWidth, setTableWidth] = React.useState("1200px");

  const classes = useStyles();
  const isFullWidth = !(tableWidth === "1200px");

  const columns = [
    { id: "created", label: "Age", minWidth: "80px" },
    { id: "url", label: "URL" },
    //retention
    {
      id: "created",
      label: "Retention",
      toolTip:
        "Files over 15 mb will be online for 1 year, files less than 15 mb will stay online forever.",
      minWidth: "100px"
    },
    { id: "link", label: "Archive" }
  ];

  if (onDelete && isAuthenticated) {
    columns.push({ id: "delete", label: "Delete" });
  }

  function getData(row, column) {
    if (column.id === "link") {
      return (
        <Link
          style={{ marginLeft: "15px" }}
          href={`/archive/${row.shortId}/${row.url}`}
          state={{ data: row }}
          target="_blank"
        >
          <LaunchIcon />
        </Link>
      );
    }
    if (column.id === "delete" && isAuthenticated && !row.isDeleted) {
      return (
        <Button onClick={() => onDelete(row.id)} disabled={!row.hasRetention}>
          {!row.hasRetention ? <AllInclusiveIcon /> : <DeleteIcon />}
        </Button>
      );
    }
    if (column.id === "created") {
      return column.label === "Retention"
        ? getRentionData(row, column)
        : formatDistance(subDays(new Date(row[column.id]), 0), new Date(), {
            addSuffix: true
          });
    }
    if (column.id === "url") {
      return (
        <div
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-all",
            wordBreak: "break-all",
            padding: "5px 0",
            display: "flex",
            alignItems: "center"
          }}
        >
          {!row.favIcon && (
            <LanguageIcon fontSize="small" style={{ marginRight: "10px" }} />
          )}

          {row.favIcon && (
            <img
              height={"16px"}
              width={"16px"}
              src={row.favIcon}
              alt="logo"
              style={{ marginRight: "10px" }}
              onError={(e) => {
                e.target.src = "./brokenimg.svg";
              }}
            />
          )}

          {row.url}
        </div>
      );
    }
    return row[column.id] || "-";
  }

  function getRentionData(row, column) {
    if (!row.hasRetention && isMinimalView) {
      return <AllInclusiveIcon style={{ marginLeft: "20px" }} />;
    }
    return (
      365 - differenceInDays(new Date(), new Date(row[column.id])) + " days"
    );
  }

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsOpen(true);
  };

  return (
    <TableContainer
      component={Paper}
      style={{ maxWidth: tableWidth, margin: "0 auto" }}
    >
      <Box
        display="flex"
        justifyContent={"space-between"}
        paddingLeft={"10px"}
        paddingRight={"10px"}
        alignItems={"center"}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <caption
          style={{ captionSide: "top", textAlign: "right" }}
          className="recentTitle"
        >
          {showLoading && (
            <CircularProgress
              color="inherit"
              style={{ width: "15px", height: "15px", marginRight: "8px" }}
            />
          )}

          {captionText}
        </caption>
      </Box>
      {!isMobile && (
        <Box display="flex" justifyContent={"flex-end"} paddingRight={"20px"}>
          <Button
            startIcon={isFullWidth ? <ReadMoreIcon /> : <OpenInFullIcon />}
            size="small"
            onClick={() => {
              setTableWidth(tableWidth === "1200px" ? "100%" : "1200px");
            }}
          >
            {isFullWidth ? "Standard View" : "Wider View"}
          </Button>
        </Box>
      )}
      <Table aria-label="caption table">
        {isMobile ? (
          <></>
        ) : (
          <TableHead>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <TableRow>
                {columns.map((column) => (
                  <Tooltip
                    title={column.toolTip}
                    key={uuidv4()}
                    PopperProps={{
                      disablePortal: false
                    }}
                    onClose={handleTooltipClose}
                    open={isOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    arrow
                  >
                    <TableCell
                      className="clrText"
                      key={uuidv4()}
                      style={{
                        minWidth: column.minWidth || "65px",
                        wordBreak: "break-word"
                      }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                      >
                        {column.label}{" "}
                        {column.toolTip && (
                          <>
                            <IconButton onClick={handleTooltipOpen}>
                              <InfoOutlinedIcon
                                style={{
                                  height: "0.7em",
                                  width: "0.7em",
                                  marginLeft: "2px"
                                }}
                              />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            </ClickAwayListener>
          </TableHead>
        )}
        <TableBody>
          {isMobile && (
            <>
              {data?.map((row) => (
                <>
                  {columns.map((column) => (
                    <TableRow key={uuidv4()} className={classes.stripedRow}>
                      <>
                        <TableCell className="clrText">
                          {column.label}
                        </TableCell>

                        <TableCell
                          style={{
                            color: row.isDeleted ? "Gray" : "inherit",
                            wordBreak: "break-word"
                          }}
                        >
                          {getData(row, column)}
                        </TableCell>
                      </>
                    </TableRow>
                  ))}
                </>
              ))}
            </>
          )}
          {!isMobile &&
            data?.map((row) => (
              <TableRow key={uuidv4()} className={classes.stripedRow}>
                {columns.map((column) => (
                  <TableCell
                    key={uuidv4()}
                    style={{
                      color: row.isDeleted ? "Gray" : "inherit",
                      wordBreak: "break-word"
                    }}
                  >
                    {getData(row, column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableView;
