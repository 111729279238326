import React from "react";
import { format } from "date-fns";
import { Container } from "@mui/material";

import TableView from "./tabular";

const RecentArchiveJobs = ({ archive, lastUpdated, showLoading }) => {
  if (!archive) return null;
  return (
    <>
      <Container style={{ marginTop: "15px", maxWidth: "100%" }}>
        <TableView
          data={archive}
          isMinimalView={true}
          showLoading={showLoading}
          captionText={
            lastUpdated &&
            `Updates every 10 sec, last updated ${format(
              lastUpdated,
              "HH:mm:ss"
            )}`
          }
          title={"Recent archives"}
        />
      </Container>
    </>
  );
};

export default RecentArchiveJobs;
