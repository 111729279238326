import React from "react";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const DateRange = ({ onDateSelected }) => {
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(dayjs(Date.now()));

  React.useEffect(() => {
    if (fromValue && toValue) {
      onDateSelected([fromValue.toDate(), toValue.toDate()]);
    }
  }, [fromValue, toValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display={"flex"} alignItems={"center"}>
        <DatePicker
          className="mxwd150"
          label="From"
          value={fromValue}
          onChange={(newValue) => setFromValue(newValue)}
        />
        {"-"}
        <DatePicker
          className="mxwd150"
          label="To"
          value={toValue}
          onChange={(newValue) => setToValue(newValue)}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateRange;
