import React from "react";
//import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Paper,
  Card,
  CardContent,
  Typography,
  Divider,
  //Link,
  Stack
} from "@mui/material";

const AccountPlan = () => {
  return (
    <>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: 450
            //height: 120
          }
        }}
      >
        <Paper elevation={0}>
          <Card
            sx={{
              background: "rgb(43 171 226 / 11%)"
              //   backgroundImage:
              //     "linear-gradient(181deg, rgba(25, 118, 210, 0.4), rgba(0, 0, 0, 0.25) );"
            }}
          >
            <CardContent>
              <Stack spacing={1}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color={"rgb(12 56 90)"}
                >
                  Free
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Archive any site for free | Download warc file | Private
                  archive
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  *Require login
                </Typography>
                <Typography color="textSecondary"> Read more</Typography>
                {/* <Link
                  color="inherit"
                  href="/about"
                  to="/about"
                  component={RouterLink}
                >
                  Read more
                </Link> */}
              </Stack>
            </CardContent>
          </Card>
        </Paper>

        <Paper elevation={0}>
          <Card
            sx={{
              background: "rgb(43 171 226 / 11%)"
              //   backgroundImage:
              //     "linear-gradient(181deg, rgba(25, 118, 210, 0.4), rgba(0, 0, 0, 0.25) );"
            }}
          >
            <CardContent>
              <Stack spacing={1} style={{ height: "122.3px" }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color={"rgb(12 56 90)"}
                >
                  Pro account
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Scheduled archive | Archive entire sites |Unlimited storage
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#1976d2"
                  fontWeight={"bold"}
                >
                  Comming soon
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Paper>
      </Box>

      <Divider style={{ width: "98%" }} />
    </>
  );
};

export default AccountPlan;
