import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const BASE_URL = "/archive";

const getArchieveList = ({
  pageIndex = 0,
  pageCount = 20,
  filter = {},
  sortBy = {}
}) => {
  let filterParam = "";
  let sortParam = "";

  if (Object.keys(filter).length > 0) {
    filterParam = JSON.stringify(filter);
  }
  if (Object.keys(sortBy).length > 0) {
    sortParam = JSON.stringify(sortBy);
  }
  return axios
    .get(
      `${BASE_URL}/allarchivejobs?pageIndex=${pageIndex}&pageCount=${pageCount}&filter=${filterParam}&sort=${sortParam}`
    )
    .then((x) => {
      return x.data;
    })
    .catch((ex) => {
      console.log("getArchieveList failed :", ex.message);
    });
};

const getTopArchieveList = () => {
  return axios
    .get(`${BASE_URL}/recentarchive/`)
    .then((x) => {
      return x.data?.archiveList;
    })
    .catch((ex) => {
      console.log("getTopArchieveList failed :", ex.message);
    });
};

const getArchieveListByUrl = (url, shortId) => {
  return axios
    .get(`${BASE_URL}/allarchivejobsbyurl?shortId=${shortId || ""}&url=${url}`)
    .then((x) => {
      return x.data?.archiveList;
    })
    .catch((ex) => {
      console.log("getTopArchieveList failed :", ex.message);
    });
};

const doArchieve = (url) => {
  return axios
    .post(`${BASE_URL}/new`, {
      url: url
    })
    .then((x) => {
      if (x.data?.status === "failed") {
        return;
      }
      return x.data?.jobId;
    })
    .catch((ex) => {
      console.log("doArchieve failed :", ex.message);
    });
};

const getArchiveDetails = (id) => {
  return axios
    .get(`${BASE_URL}/jobdetail/${id}`)
    .then((x) => {
      if (x.data?.archive) {
        const { jobId, status, created, result, url, shortId, versions } =
          x.data.archive;
        return {
          jobId: jobId,
          id: uuidv4(),
          status,
          created,
          url: url,
          shortId: shortId,
          result: result,
          versions: versions
        };
      }
    })
    .catch((ex) => {
      console.log("getArchiveDetails failed :", ex.message);
    });
};

const getArchieveSummary = () => {
  return axios
    .get(`${BASE_URL}/archivesummary`)
    .then((x) => {
      return x.data;
    })
    .catch((ex) => {
      console.log("getArchieveSummary failed :", ex.message);
    });
};

const doAdminAuth = (username, password) => {
  return axios
    .post(`${BASE_URL}/auth`, {
      username,
      password
    })
    .then((x) => {
      return Promise.resolve(x.data);
    })
    .catch((ex) => {
      console.log("doAdminAuth failed :", ex.message);
      return Promise.reject();
    });
};

const notifyArchiveJobCompleted = (id) => {
  return axios
    .post(`${BASE_URL}/notifyarchive`, {
      jobId: id
    })
    .then((x) => {
      if (x.data?.status === "failed") {
        return;
      }
      return x.data?.id;
    })
    .catch((ex) => {
      console.log("notifyArchiveJobCompleted failed :", ex.message);
    });
};

const deleteArchive = (id) => {
  return axios
    .delete(`${BASE_URL}/deletearchive/${id}`)
    .then((x) => {
      return x.data;
    })
    .catch((ex) => {
      console.log("deleteArchive failed :", ex.message);
    });
};

const updateArchive = (id, field, value) => {
  return axios
    .put(`${BASE_URL}/updatearchive/${id}`, {
      archiveInfo: {
        [field]: value
      }
    })
    .then((x) => {
      return x.data;
    })
    .catch((ex) => {
      console.log("updateArchive failed :", ex.message);
    });
};

const getDownloadUrl = (jobId) => {
  return axios
    .get(`${BASE_URL}/downloadurl/${jobId}`)
    .then((x) => {
      return x.data;
    })
    .catch((ex) => {
      console.log("getDownloadUrl failed :", ex.message);
    });
};

export {
  getArchieveList,
  getTopArchieveList,
  doArchieve,
  getArchiveDetails,
  doAdminAuth,
  notifyArchiveJobCompleted,
  deleteArchive,
  getArchieveListByUrl,
  getArchieveSummary,
  getDownloadUrl,
  updateArchive
};
