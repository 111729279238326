import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Drawer,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Slider,
  Typography,
  Button,
  Divider,
  TextField,
  Switch,
  Badge
} from "@mui/material";

import FeedbackSelection from "../feedbackSelection";
import StorageSlider from "../filters/storageSlider";

const FilterSidePopup = ({ onFilterApply }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [storageRange, setStorageRange] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [domainSearch, setDomainSearch] = useState("");
  const [urlSearch, setUrlSearch] = useState(""); //statusChecked
  const [statusChecked, setStatusChecked] = useState(false);
  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterClick = () => {
    // Handle filter logic here

    let filter = {};

    if (dateRange) {
      filter.dateRange = getRangeChange(dateRange);
    }
    if (storageRange && storageRange !== 1) {
      filter.storageRange = storageRange;
    }

    if (feedback) {
      filter.feedback = feedback;
    }

    if (domainSearch?.length > 0) {
      filter.domain = domainSearch;
    }

    if (urlSearch?.length > 0) {
      filter.url = urlSearch;
    }

    if (statusChecked) {
      filter.status = "failed";
    }

    // Close the filter popup
    setIsOpen(false);
    onFilterApply(filter);
    setHasFilterApplied(Object.keys(filter).length > 0);
  };

  const resetFilter = () => {
    clearFilter();
    // Close the filter popup
    setIsOpen(false);
    onFilterApply({});
    setHasFilterApplied(false);
  };

  const handleFilterClose = () => {
    setIsOpen(false);
  };

  function onFeedbackChanged(value) {
    setFeedback(value);
  }

  function onStorageSizeChange(value) {
    setStorageRange(value);
  }

  function onDateRangeChange(_, newValue) {
    setDateRange(newValue);
  }

  function handleStatusChange(event) {
    setStatusChecked(event.target.checked);
  }

  function getRangeChange() {
    let currentDate = new Date();
    let lastMonthDate = new Date();

    switch (dateRange) {
      case 0:
        return null;
      case 15:
        return currentDate;
      case 30:
        let lastWeekDate = new Date();
        lastWeekDate.setDate(currentDate.getDate() - 7);
        return lastWeekDate;
      case 45:
        lastMonthDate = new Date();
        lastMonthDate.setMonth(currentDate.getMonth() - 1);
        return lastMonthDate;
      case 60:
        lastMonthDate = new Date();
        lastMonthDate.setMonth(currentDate.getMonth() - 2);
        return lastMonthDate;
      case 75:
        lastMonthDate = new Date();
        lastMonthDate.setMonth(currentDate.getMonth() - 3);
        return lastMonthDate;
      case 90:
        lastMonthDate = new Date();
        lastMonthDate.setMonth(currentDate.getMonth() - 6);
        return lastMonthDate;
      case 100:
        lastMonthDate = new Date();
        lastMonthDate.setMonth(currentDate.getFullYear() - 1);
        return lastMonthDate;
      default:
        return null;
    }
  }

  function clearFilter() {
    setStorageRange(null);
    setDateRange(0);
    setFeedback(null);
    setDomainSearch("");
    setUrlSearch("");
    setStatusChecked(false);
  }

  function getDateRangeSlider() {
    const marks = [
      {
        value: 0,
        label: "All"
      },
      {
        value: 15,
        label: "TD"
      },
      {
        value: 30,
        label: "1 W"
      },
      {
        value: 45,
        label: "1 M"
      },
      {
        value: 60,
        label: "2 M"
      },
      {
        value: 75,
        label: "3 M"
      },
      {
        value: 90,
        label: "6 M"
      },
      {
        value: 100,
        label: "Y"
      }
    ];

    return (
      <Box sx={{ width: 350 }}>
        <Typography id="input-slider" gutterBottom>
          Date Range
        </Typography>
        <Slider
          aria-label="Always visible"
          defaultValue={0}
          getAriaValueText={(val) => val}
          step={15}
          marks={marks}
          valueLabelDisplay="off"
          value={dateRange || 0}
          onChange={onDateRangeChange}
        />
      </Box>
    );
  }

  function getFeedBackSelect() {
    return (
      <Box>
        <Typography id="input-slider" gutterBottom>
          Feedback
        </Typography>
        <FeedbackSelection
          onFeedbackChanged={onFeedbackChanged}
          value={feedback}
        />
      </Box>
    );
  }

  return (
    <div>
      <Button onClick={handleToggle}>
        <Badge badgeContent={hasFilterApplied ? "+" : null} color="primary">
          <FilterListIcon />
        </Badge>
        Filter
      </Button>
      <Drawer anchor="right" open={isOpen} onClose={handleToggle}>
        <Box>
          <ListItem>
            <ListItemText>Filter</ListItemText>
            <IconButton onClick={handleFilterClose}>
              <CloseIcon />
            </IconButton>
          </ListItem>
          <ListItem>{getDateRangeSlider()}</ListItem>
          <Divider />
          <ListItem>{getFeedBackSelect()}</ListItem>
          <Divider />
          <ListItem>
            <StorageSlider
              onChange={onStorageSizeChange}
              storageValue={storageRange}
            />
          </ListItem>
          <Divider />

          <ListItem>
            <ListItemText>Failed archives</ListItemText>
            <Switch
              size="medium"
              checked={statusChecked}
              onChange={handleStatusChange}
            />
          </ListItem>
          <Divider />

          <ListItem>
            <TextField
              fullWidth
              label="Domain search"
              value={domainSearch}
              onChange={(event) => setDomainSearch(event.target.value)}
            />
          </ListItem>
          <Divider />

          <ListItem>
            <TextField
              fullWidth
              label="URL search"
              value={urlSearch}
              onChange={(event) => setUrlSearch(event.target.value)}
            />
          </ListItem>
          <Divider />

          <ListItem>
            <Button
              fullWidth={true}
              variant="contained"
              onClick={handleFilterClick}
            >
              Filter
            </Button>
          </ListItem>
          <ListItem>
            <Button fullWidth={true} variant="outlined" onClick={clearFilter}>
              Clear
            </Button>
          </ListItem>
          <ListItem>
            <Button
              fullWidth={true}
              variant="contained"
              onClick={resetFilter}
              color="warning"
            >
              Reset Filter
            </Button>
          </ListItem>
        </Box>
      </Drawer>
    </div>
  );
};

export default FilterSidePopup;
