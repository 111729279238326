import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Stack, Link } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import { formatDateTime } from "../helpers/dateHelper";

//import { getDownloadUrl } from "../services/webArchiveService";

export default function ArchiveResult({ archive }) {
  //const [warchDownloadUrl, setWarchDownloadUrl] = React.useState();

  // React.useEffect(() => {
  //   if (archive) {
  //     getDownloadUrl(archive.jobId).then((x) => {
  //       setWarchDownloadUrl(x?.archiveUrl);
  //     });
  //   }
  // }, [archive]);

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard:", text);
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
      });
  }
  if (!archive) {
    return <></>;
  }
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="caption">Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            <Typography variant="subtitle2" className="archResult">
              Date {formatDateTime(archive.created, false, true)}
              <br />
              Time {formatDateTime(archive.created, true, false)}
            </Typography>

            <Typography variant="subtitle2" className="archResult">
              URL <br /> {archive.url}{" "}
            </Typography>
            <Typography variant="subtitle2" className="archResult">
              Domain <br /> {archive.domain}{" "}
            </Typography>
            <Typography variant="subtitle2" className="archResult">
              URL to this webarchive
              <br />
              <Link
                href={`/archive/${archive?.shortId}/${archive.url}`}
                target="_blank"
              >
                {`https://webarchive.io/archive/${archive?.shortId}/${archive.url}`}{" "}
              </Link>
              <Button
                onClick={() => {
                  copyToClipboard(
                    `https://webarchive.io/archive/${archive?.shortId}/${archive.url}`
                  );
                }}
                style={{
                  textTransform: "none",
                  textDecorationLine: "underline"
                }}
              >
                <span>Copy Url</span>
              </Button>
            </Typography>
            <Typography variant="subtitle2">
              file size: {archive.size} {"  "}
              {/* {warchDownloadUrl && (
                <Link href={warchDownloadUrl}>Download Url</Link>
              )} */}
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
