import React from "react";
import { useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ArchiveDataGrid from "../components/archiveDataGrid";
import { getArchieveListByUrl } from "../services/webArchiveService";

const useStyles = makeStyles((theme) => ({
  customCaption: {
    fontWeight: "700 !important",
    color: "rgb(103, 116, 142)"
  }
}));

const ArchiveListView = () => {
  const [archive, setArchive] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const id = useParams()["*"];
  const classes = useStyles();

  React.useEffect(() => {
    getArchieveListByUrl(id).then((x) => {
      setArchive(x || []);
      setLoading(false);
    });
  }, [id]);

  function getArchiveDates() {
    if (archive && archive.length > 0) {
      const dateReducer = (acc, obj) => {
        const currentDate = new Date(obj.created);

        if (!acc.start || currentDate < acc.start) {
          acc.start = currentDate;
        }

        if (!acc.end || currentDate > acc.end) {
          acc.end = currentDate;
        }

        return acc;
      };
      const { start, end } = archive.reduce(dateReducer, {});

      const startDate = start.toISOString().split("T")[0];
      const endDate = end.toISOString().split("T")[0];

      if (startDate === endDate) {
        return `The list of archive that has been made ${startDate}`;
      }
      return `The list of archive that has been made between ${startDate} to ${endDate}`;
    }
    return "";
  }

  function getSummary() {
    if (archive?.length > 0) {
      return (
        <Stack padding={"10px"} style={{ background: "#fafafb" }}>
          <Typography variant="caption" className={classes.customCaption}>
            {getArchiveDates()}
          </Typography>
          <Typography variant="caption" className={classes.customCaption}>
            URL: {archive[0].url}
          </Typography>
          <Typography
            variant="caption"
            className={classes.customCaption}
          >{`Total of ${archive.length} has been made so far.`}</Typography>
        </Stack>
      );
    }
  }

  return (
    <>
      {getSummary()}
      <ArchiveDataGrid
        data={archive}
        hideAction={true}
        dataLoading={loading}
      ></ArchiveDataGrid>
    </>
  );
};

export default ArchiveListView;
