import React from 'react';
import { Container, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#f5f5f5', padding: '20px 0', marginTop: '20px' }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="textPrimary" gutterBottom>
                            <img src="favicon.svg" alt="WebArchive" style={{ height: '160px', width: '160px' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="textPrimary" gutterBottom>
                            Company
                        </Typography>
                        <Link href="/about" color="textSecondary" display="block">About us</Link>
                        <Link href="/contact" color="textSecondary" display="block">Contact us</Link>
                        <Link href="#" color="textSecondary" display="block">Compliance</Link>
                        <Link href="#" color="textSecondary" display="block">Changelog</Link>
                        <Link href="/terms" color="textSecondary" display="block">Terms and Conditions</Link>
                        <Link href="#" color="textSecondary" display="block">Privacy</Link>
                    </Grid>
                </Grid>
                <Grid container spacing={4} style={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" color="textSecondary">
                            @2023 All Copyright Reserved{" "}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                        <Typography variant="body2" color="textSecondary">
                            info@webarchive.io
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;