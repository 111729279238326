// import React, { useState } from "react";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
// const CookiePopup = () => {
//   const [open, setOpen] = useState(true);
//   const handleClose = () => {
//     setOpen(false);
//   };
//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>Accept Cookies</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           We use cookies to improve your experience. By using our website, you
//           consent to the use of cookies.
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose} color="primary">
//           Accept
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };
// export default CookiePopup;
import React, { useState } from "react";
import { Snackbar, Button } from "@mui/material";

const CookiePopup = () => {
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (!localStorage.getItem("constent")) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    setOpen(false);
    localStorage.setItem("constent", "true");
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      message="This website uses cookies. By continuing to use this site, you consent to the use of cookies."
      action={
        <Button color="inherit" size="small" onClick={handleAccept}>
          Accept
        </Button>
      }
    />
  );
};

export default CookiePopup;
