import axios from "axios";

const BASE_URL = "/archive/site";
const getBlackListSites = () => {
  return axios
    .get(`${BASE_URL}/blacklist`)
    .then((x) => {
      if (x.data?.sites) {
        return x.data?.sites;
      }
    })
    .catch((ex) => {
      console.log("getBlackListSites failed :", ex.message);
    });
};

const addBlackListSites = (site) => {
  return axios
    .post(`${BASE_URL}/blacklist`, {
      siteInfo: {
        site: site,
        useRegex: false
      }
    })
    .then((x) => {
      return x.data?.siteId;
    })
    .catch((ex) => {
      console.log("addBlackListSites failed :", ex.message);
    });
};

const deleteBlackListSites = (id) => {
  return axios
    .delete(`${BASE_URL}/blacklist/${id}`)
    .then((x) => {
      if (x.data?.status === "failed") {
        return;
      }
      return x.data?.status === "deleted";
    })
    .catch((ex) => {
      console.log("addBlackListSites failed :", ex.message);
    });
};

export { getBlackListSites, addBlackListSites, deleteBlackListSites };
