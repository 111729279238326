import React from "react";
import { NavLink } from "react-router-dom";
import ArchiveIcon from "@mui/icons-material/Archive";
import BlockIcon from "@mui/icons-material/Block";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Toolbar, Button, IconButton } from "@mui/material";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Box
} from "@mui/material";

import useUserAuth from "../hooks/useUserAuth";
import LoginDialog from "./loginDialog";

const TopHeader = () => {
  //const theme = useTheme();

  //const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { isAuthenticated } = useUserAuth();
  const [isOpen, setIsOpen] = React.useState(
    !isAuthenticated && window.location.href.includes("/diw55ketc")
  );
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (window.location.href.includes("/archive/")) {
    return <></>;
  }
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Button color="inherit" to="/" LinkComponent={NavLink}>
              Home
            </Button>
          </Box>
          {/* <IconButton
            href="/"
            edge="start"
            className={"menuButton"}
            color="inherit"
            aria-label="menu"
          ></IconButton> */}

          {/* <Link href="/" sx={{ flexGrow: 1 }}>
            <img src={logo} height={"60px"} width={"60px"} alt="home" />
          </Link> */}

          {!isAuthenticated && (
            <Button
              edge="end"
              color="inherit"
              LinkComponent={NavLink}
              to={"/archives"}
            >
              list archived sites
            </Button>
          )}

          {/* <Button color="inherit" onClick={handleLogin}>
            Login
          </Button> */}

          {/* <Button color="inherit" to="/about" LinkComponent={NavLink}>
            About
          </Button> */}
          {isAuthenticated && (
            <IconButton onClick={handleDrawerToggle}>
              <MenuIcon color="action" />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <LoginDialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      {/* <Link href="/" sx={{ flexGrow: 1 }} className="logo">
        <Box display={"flex"} alignItems={"center"}>
          <img
            sizes="((max-width:600px) 80px)"
            src={logo}
            height={"100px"}
            width={"130px"}
            alt="home"
            style={{
              height: isMobile ? "94px" : "100px",
              width: isMobile ? "105px" : "130px",
              objectFit: isMobile ? "fill" : "none"
            }}
          />
          <span
            style={{
              color: "#a4c7e6",
              fontStyle: "italic"
            }}
          >
            beta
          </span>
        </Box>
      </Link> */}

      <DrawerComponent open={drawerOpen} onClose={handleDrawerToggle} />
    </>
  );
};

export default TopHeader;

const DrawerComponent = ({ open, onClose }) => {
  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <List style={{ minWidth: "250px" }}>
        <ListItem disablePadding onClick={onClose}>
          <ListItemButton LinkComponent={NavLink} to={"/archives"}>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText primary="Archive" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding onClick={onClose}>
          <ListItemButton LinkComponent={NavLink} to={"/bannedsites"}>
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            <ListItemText primary="Banned Sites" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding onClick={onClose}>
          <ListItemButton LinkComponent={NavLink} to={"/dashboard"}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};
