import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import Login from "./login";

const LoginDialog = ({ open, onClose }) => {
  function handleClose() {
    onClose && onClose();
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Admin Login</DialogTitle>
        <Login />
      </Dialog>
    </>
  );
};

export default LoginDialog;
