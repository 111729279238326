export const arraysAreEqual = (arr1, arr2, compareprop) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const ar1 = [...arr1];
  const ar2 = [...arr2];

  ar1.sort((a, b) => a[compareprop] - b[compareprop]);
  ar2.sort((a, b) => a[compareprop] - b[compareprop]);

  for (let i = 0; i < ar1.length; i++) {
    if (ar1[i][compareprop] !== ar2[i][compareprop]) {
      return false;
    }
  }

  return true;
};
