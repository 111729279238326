import React, { useState } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import {
  TextField,
  FormControl,
  Box,
  Select,
  MenuItem,
  Container,
  Snackbar,
  Button,
  useTheme,
  useMediaQuery
} from "@mui/material";

import ArchiveDataGrid from "../components/archiveDataGrid";
import DateRange from "../components/dateRange";
import FilterSidePopup from "../components/dialogs/filterSidePopup";
import useArchivePagination from "../hooks/useArchivePagination";
import useUserAuth from "../hooks/useUserAuth";
import {
  deleteArchive,
  getArchieveList,
  updateArchive
} from "../services/webArchiveService";

function DetailView() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterValue, setFilterValue] = useState("DOMAIN");

  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [tableWidth, setTableWidth] = useState("1200px");
  //const [loading, setLoading] = React.useState(true);

  const { isAuthenticated } = useUserAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isFullWidth = !(tableWidth === "1200px");

  const {
    archiveList,
    loading,
    totalArchiveRowCount,
    setPageIndex,
    setPageCount,
    setFilter,
    setSortBy
  } = useArchivePagination();

  React.useEffect(() => {
    setData(archiveList);
    setSearchedData(archiveList);
  }, [archiveList]);

  // React.useEffect(() => {
  //   getArchieveList({ pageIndex: 0, pageCount: 20 }).then((webData) => {
  //     setData(webData);
  //     setSearchedData(webData);
  //     setLoading(false);
  //   });
  // }, []);

  React.useEffect(() => {
    if (!filterValue) {
      setSearchedData(data);
    }

    if (filterValue === "DATE") {
      return;
    }

    const filterMap = {
      DOMAIN: "domain",
      // DATE: "created",
      // TITLE: "title",
      URL: "url"
    };

    if (searchTerm?.length > 0) {
      setSearchedData(
        data.filter((x) => x[filterMap[filterValue]].includes(searchTerm))
      );
    } else {
      setSearchedData(data);
    }
  }, [filterValue, data, searchTerm]);

  function onDateRangeSelect(dates) {
    setSearchedData(
      data.filter(
        (x) =>
          new Date(x.created) >= dates[0] && new Date(x.created) <= dates[1]
      )
    );
  }

  async function markArchiveDeleted(id) {
    await deleteArchive(id);
    setShowToast(true);
    const index = searchedData.findIndex((x) => x.id === id);
    if (index > -1) {
      searchedData[index].isDeleted = true;
      setSearchedData(searchedData);
    }
    // getArchieveList().then((webData) => {
    //   setData(webData);
    //   setSearchedData(webData);
    // });
  }

  async function updateArchiveField({ id, field, value }) {
    await updateArchive(id, field, value);
    //setShowToast(true);
    getArchieveList().then((webData) => {
      setData(webData);
      setSearchedData(webData);
    });
  }

  function handlePageCallback({ page, pageSize }) {
    setPageIndex(page);
    setPageCount(pageSize);
  }

  const handleFilterApplied = (filters) => {
    setFilter(filters);
  };

  const handleSortCallback = (sortParams) => {
    setSortBy(sortParams);
  };

  const handleFilterDialogClose = () => {};

  return (
    <Container style={{ marginTop: "20px", maxWidth: tableWidth }}>
      <Box
        display={"flex"}
        justifyItems={"center"}
        marginBottom={"10px"}
        width={"100%"}
      >
        <TextField
          disabled={filterValue === "DATE"}
          label="Quick Search"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />

        <FormControl style={{ minWidth: "102px", marginRight: "15px" }}>
          <Select
            value={filterValue}
            onChange={(event) => setFilterValue(event.target.value)}
          >
            <MenuItem value="DOMAIN">Domain</MenuItem>
            {/* <MenuItem value="DATE">Date</MenuItem>
            <MenuItem value="TITLE" disabled>
              Title
            </MenuItem> */}
            <MenuItem value="URL">URL</MenuItem>
          </Select>
        </FormControl>
        {filterValue === "DATE" && (
          <DateRange
            onDateSelected={(x) => {
              onDateRangeSelect(x);
            }}
          />
        )}

        {isAuthenticated && (
          <Box justifySelf={"end"}>
            <FilterSidePopup
              onFilterApply={handleFilterApplied}
              onClose={handleFilterDialogClose}
              initialFilters={{ name: "" }}
            />
          </Box>
        )}
        <Box display={"flex"} justifyContent={"end"} flexGrow={1}>
          {/* <IconButton
            size="small"
            onClick={() => {}}
            aria-label="full width"
            color="primary"
          >
            <WidthFullIcon fontSize="small" />
          </IconButton> */}
          {!isMobile && (
            <Button
              startIcon={isFullWidth ? <ReadMoreIcon /> : <OpenInFullIcon />}
              size="small"
              onClick={() => {
                setTableWidth(tableWidth === "1200px" ? "100%" : "1200px");
              }}
            >
              {isFullWidth ? "Standard View" : "Wider View"}
            </Button>
          )}
        </Box>
      </Box>
      <ArchiveDataGrid
        data={
          isAuthenticated
            ? searchedData
            : searchedData?.filter((x) => x.status !== "failed")
        }
        onDelete={(id) => markArchiveDeleted(id)}
        onItemUpdate={(dt) => updateArchiveField(dt)}
        dataLoading={loading}
        totalRowCount={totalArchiveRowCount}
        onPageCallback={(page) => handlePageCallback(page)}
        onSortingCallback={(sort) => handleSortCallback(sort)}
      />
      {/* <TableView
        data={searchedData}
        onDelete={(id) => markArchiveDeleted(id)}
      /> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        onClose={() => {
          setShowToast(false);
        }}
        message="Site has been removed"
        key={"sn"}
      />
    </Container>
  );
}

export default DetailView;
