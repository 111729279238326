import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Alert,
  AlertTitle,
} from "@mui/material";
import emailjs from 'emailjs-com';
import HCaptcha from '@hcaptcha/react-hcaptcha';

// Function to validate email format
function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

// Add a function to handle form submission
async function handleFormSubmit(event, name, email, message, hcaptchaToken, setAlert) {
    event.preventDefault();

    if (!isValidEmail(email)) {
        setAlert({ severity: 'error', message: 'Please enter a valid email address.' });
        return;
    }

    if (!hcaptchaToken) {
        setAlert({ severity: 'error', message: 'Please complete the CAPTCHA.' });
        return;
    }

    const templateParams = {
        from_name: name,
        from_email: email,
        message: message,
        'h-captcha-response': hcaptchaToken, // Include the hCaptcha token
    };

    try {
        const result = await emailjs.send(
            'webarchive_mailtrap_test', // Replace with your EmailJS service ID
            'web_arch_test_template', // Replace with your EmailJS template ID
            templateParams,
            '6cF4gUX4ul7Vuk4zf' // Replace with your EmailJS user ID
        );

        setAlert({ severity: 'success', message: 'Message sent successfully!'});
        console.log(JSON.stringify(result));
    } catch (error) {
        setAlert({ severity: 'error', message: 'Failed to send message.'});
    }
}

// Add the contact form to the view
const ContactView = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [hcaptchaToken, setHcaptchaToken] = useState(null);
    const [alert, setAlert] = useState(null);

    return (
        <Container style={{ maxWidth: "1000px"}}>
            {alert && (
                <Alert severity={alert.severity} onClose={() => setAlert(null)}>
                    <AlertTitle>{alert.severity === 'success' ? 'Success' : 'Error'}</AlertTitle>
                    {alert.message}
                </Alert>
            )}
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', paddingRight: '20px', paddingTop: '82px'}}>
                    <form onSubmit={(e) => handleFormSubmit(e, name, email, message, hcaptchaToken, setAlert)} style={{ width: '100%' }}>
                        <div style={{ marginBottom: '5px' }}>
                            <label htmlFor="name"></label>
                            <TextField 
                                id="name"
                                label="Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                style={{ width: '100%', boxSizing: 'border-box', paddingBottom: '10px' }}
                            />
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <label htmlFor="email"></label>
                            <TextField 
                                id="email"
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                style={{ width: '100%', boxSizing: 'border-box', paddingBottom: '10px' }}
                            />
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <label htmlFor="message"></label>
                            <TextField 
                                id="message" 
                                label="Message"
                                variant="outlined"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                style={{ width: '100%', boxSizing: 'border-box', paddingBottom: '10px' }}
                                multiline
                                rows={5}
                            ></TextField>
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <HCaptcha
                                sitekey="50e63f7c-1a14-4fe0-a042-49426056e688" // Replace with your hCaptcha site key
                                onVerify={(token) => setHcaptchaToken(token)}
                            />
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Button type="submit" variant="contained">Send</Button>
                        </div>
                    </form>
                </div>
                <div style={{ flex: 1 }}>
                    <h1>Contact</h1>
                    <p>
                    For all questions, bug reports, feedback, feature requests,
                    contact us via this form.
                    </p>
                    <p>
                    Visit us at
                    our headquarters in Sweden
                    </p>
                    <p>
                    Webarchive - Sweden <br/>
                    Ättehögsgatan 8A-B <br/>
                    416 74 Gothenburg
                    </p>
                </div>
            </div>
        </Container>
    );
};

export default ContactView;