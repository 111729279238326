import React from "react";
import { differenceInDays, formatDistance, subDays, format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import LaunchIcon from "@mui/icons-material/Launch";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Link,
  useTheme,
  useMediaQuery,
  Alert,
  Table,
  TableCell,
  TableRow,
  Button,
  Tooltip
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { timeConversion } from "../helpers/dateHelper";
import useUserAuth from "../hooks/useUserAuth";
import AddEditNote from "./addEditNote";
import FeedbackSelection from "./feedbackSelection";

const useStyles = makeStyles({
  stripedRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5"
    }
  },
  trbg: {
    backgroundColor: "#f5f5f5"
  }
});
const ArchiveDataGrid = ({
  data,
  onDelete,
  onItemUpdate,
  dataLoading,
  totalRowCount,
  onPageCallback,
  onSortingCallback,
  hideAction = false
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated } = useUserAuth();

  const getColumnDef = () => {
    if (isMobile) {
      const col = [
        { id: "created", label: "Age" },
        { id: "domain", label: "Domain" },
        { id: "url", label: "URL" },
        { id: "title", label: "Title" },
        { id: "size", label: "Size" },
        { id: "link", label: "Archive" }
      ];
      if (isAuthenticated && !hideAction) {
        col.push({ id: "delete", label: "Action", sortable: false });
      }
      return [
        {
          headerName: "",
          flex: 1,
          renderCell: (params) => (
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-all",
                wordBreak: "break-all",
                padding: "5px 0",
                width: "100%"
              }}
            >
              {col.map((cl, index) => (
                <Table>
                  <TableRow
                    key={uuidv4()}
                    className={index % 2 === 0 ? classes.trbg : ""}
                  >
                    <TableCell width={100} className="clrText">
                      {cl.label}
                    </TableCell>

                    <TableCell>{getData(params.row, cl)}</TableCell>
                  </TableRow>
                </Table>
              ))}
            </div>
          )
        }
      ];
    }
    const columns = [
      {
        field: "created",
        headerName: "Age",
        width: 120,
        sortable: isAuthenticated,
        valueGetter: (params) => {
          if (isAuthenticated) {
            return format(new Date(params.value), "yyyy-MM-dd HH:mm:ss");
          }
          return formatDistance(
            subDays(new Date(params.value), 0),
            new Date(),
            {
              addSuffix: true
            }
          );
        },
        renderCell: (params) => (
          <label
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-all",
              padding: "5px 0"
            }}
          >
            {params.value}
          </label>
        )
      },
      {
        field: "domain",
        headerName: "Domain",
        flex: isAuthenticated ? 0.3 : 0.1,
        sortable: isAuthenticated
      },
      {
        field: "feedbackTest",
        headerName: "Feedback",
        width: 160,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <FeedbackSelection
            value={params.value}
            onFeedbackChanged={(e) => {
              onFeedbackChanged(e, params);
            }}
          />
        )
      },
      {
        field: "notes",
        headerName: "Notes",
        width: 60,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <AddEditNote
            value={params.value}
            onNotesChanged={(e) => {
              onNotesChanged(e, params);
            }}
          />
        )
      },
      {
        field: "url",
        headerName: "URL",
        flex: 0.5,
        sortable: isAuthenticated,
        renderCell: (params) => (
          <div
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-all",
              wordBreak: "break-all",
              padding: "5px 0",
              display: "flex",
              alignItems: "center"
            }}
          >
            {!params.row.favIcon && (
              <LanguageIcon fontSize="small" style={{ marginRight: "10px" }} />
            )}
            {params.row.favIcon && (
              <img
                height={"16px"}
                width={"16px"}
                src={params.row.favIcon}
                alt="logo"
                style={{ marginRight: "10px" }}
                onError={(e) => {
                  e.target.src = "./brokenimg.svg";
                }}
              />
            )}

            {params.value}
          </div>
        )
      },
      // { field: "title", headerName: "Title", width: 50 },
      {
        field: "elapsedDuration",
        headerName: "Duration",
        sortable: isAuthenticated,
        valueGetter: (params) => {
          if (params.value) {
            return timeConversion(params.value);
          } else {
            return "-";
          }
        }
      },
      {
        field: "archiveFileSize",
        headerName: "Size",
        width: 100,
        sortable: isAuthenticated,
        filterable: false,
        valueFormatter: (params) => {
          function getArchiveFileSize(bytes) {
            const sizes = ["Bytes", "KB", "MB", "GB"];
            const base = 1024;
            const i = Math.floor(Math.log(bytes) / Math.log(base));
            const convertedValue = parseFloat(
              (bytes / Math.pow(base, i)).toFixed(2)
            );
            const convertedUnit = sizes[i];
            return `${convertedValue} ${convertedUnit}`;
          }
          if (params.value == null) {
            return "";
          }
          return getArchiveFileSize(params.value);
        }
      },
      {
        field: "link",
        headerName: "Archive",
        sortable: false,
        width: 80,
        filterable: false,
        renderCell: (params) => {
          return (
            <div
              style={{
                textAlign: "center",
                width: "60%",
                opacity: params.row.isDeleted ? 0.5 : 1.0,
                pointerEvents: params.row.isDeleted ? "none" : "auto"
              }}
            >
              {params.row.status !== "failed" ? (
                <Link
                  href={`/archive/${params.row.shortId}/${params.row.url}`}
                  state={{ data: params.row }}
                  target="_blank"
                >
                  <LaunchIcon />
                </Link>
              ) : (
                <Tooltip
                  title={params.row.error}
                  key={uuidv4()}
                  PopperProps={{
                    disablePortal: false
                  }}
                  placement="top"
                  arrow
                >
                  <WarningIcon htmlColor="red" />
                </Tooltip>
              )}
            </div>
          );
        }
      }
    ];

    if (isAuthenticated && !hideAction) {
      columns.push({
        headerName: "Action",
        width: 70,
        field: "actions",
        type: "actions",
        getActions: getAction,
        hideable: true,
        filterable: false
      });
    }

    columns.push({
      field: "completed",
      headerName: "Retention",
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (param) => {
        if (!param.row.hasRetention) {
          return <AllInclusiveIcon style={{ marginLeft: "20px" }} />;
        }
        return (
          365 -
          differenceInDays(new Date(), new Date(param.row.completed)) +
          " days"
        );
      }
    });

    if (!isAuthenticated) {
      return columns.filter(
        (x) =>
          x.field !== "notes" &&
          x.field !== "feedbackTest" &&
          x.field !== "elapsedDuration"
      );
    }
    return columns;
  };

  function onNotesChanged(value, params) {
    onItemUpdate && onItemUpdate({ id: params.row.id, field: "notes", value });
  }

  function onFeedbackChanged(value, params) {
    onItemUpdate &&
      onItemUpdate({ id: params.row.id, field: "feedbackTest", value });
  }

  function getData(row, column) {
    if (column.id === "link") {
      return (
        <Link
          style={{ marginLeft: "15px" }}
          href={`/archive/${row.shortId}/${row.url}`}
          state={{ data: row }}
          target="_blank"
        >
          <LaunchIcon />
        </Link>
      );
    }
    if (column.id === "delete" && isAuthenticated && !row.isDeleted) {
      return (
        <Button onClick={() => onDelete(row.id)} disabled={!row.hasRetention}>
          {!row.hasRetention ? <AllInclusiveIcon /> : <DeleteIcon />}
        </Button>
      );
    }
    if (column.id === "created") {
      return column.label === "Retention"
        ? getRentionData(row, column)
        : formatDistance(subDays(new Date(row[column.id]), 0), new Date(), {
            addSuffix: true
          });
    }
    return row[column.id] || "-";
  }

  function getRentionData(row, column) {
    if (!row.hasRetention) {
      return <AllInclusiveIcon style={{ marginLeft: "20px" }} />;
    }
    return (
      365 - differenceInDays(new Date(), new Date(row[column.id])) + " days"
    );
  }

  function handleDeleteClick(id) {
    onDelete && onDelete(id);
  }

  function getAction({ id, row }) {
    const { isDeleted } = row;

    return [
      <GridActionsCellItem
        style={{ textAlign: "center" }}
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => handleDeleteClick(id)}
        color="inherit"
        disabled={isDeleted}
      />
    ];
  }

  const handleSortModelChange = (params) => {
    // Extract sorting parameters from the DataGrid sort model
    const sortingParams = params.map((sort) => ({
      field: sort.field,
      order: sort.sort
    }));

    onSortingCallback && onSortingCallback(sortingParams);
  };

  return (
    <>
      {isMobile && <Alert severity="info">Use desktop for best viewing</Alert>}
      <DataGrid
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "transparent"
          }
        }}
        slots={
          isMobile
            ? {
                columnHeaders: () => null
              }
            : {}
        }
        getRowHeight={() => "auto"}
        rows={data}
        editMode="row"
        columns={getColumnDef()}
        rowSelection={false}
        responsive
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } }
        }}
        pageSizeOptions={[20, 50, 100]}
        getRowClassName={() => (!isMobile ? classes.stripedRow : "")}
        autoHeight={true}
        loading={dataLoading}
        rowCount={totalRowCount}
        paginationMode={"server"}
        onPaginationModelChange={onPageCallback}
        onSortModelChange={handleSortModelChange}
        disableColumnMenu
      ></DataGrid>
    </>
  );
};

export default ArchiveDataGrid;

//https://mui.com/store/previews/mira-pro-react-material-admin-dashboard/
