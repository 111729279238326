import React from "react";
import { Alert } from "@mui/material";

import { getBlackListSites } from "../services/adminService";

const useArchive = ({ url }) => {
  const [sites, setSites] = React.useState(null);
  React.useEffect(() => {
    if (!sites) {
      getBlackListSites().then((x) => {
        setSites(x.map((x) => x.site));
      });
    }
  });

  function restrictWebsites(websites, siteName) {
    const filteredWebsites = websites?.filter((url) => {
      // Check if the siteName exists in the URL or if it's a partial match
      if (url && url.indexOf("*") !== -1) {
        return siteName.indexOf(url.replace("*", "")) !== -1;
      } else {
        return trimLastCharacter(url) === trimLastCharacter(siteName);
      }
    });

    return filteredWebsites;
  }

  function isRestrictedSite() {
    return restrictWebsites(sites, url)?.length > 0;
  }

  function bandedSiteInfo() {
    return (
      url &&
      sites &&
      isRestrictedSite() && (
        <Alert severity="info">
          Site is not allowed to archive, see our terms of use.
        </Alert>
      )
    );
  }

  return { bandedSiteInfo, isRestrictedSite };
};

export default useArchive;

function trimLastCharacter(str) {
  if (str.charAt(str.length - 1) === "/") {
    return str.slice(0, -1); // Trim last character
  }
  return str; // Return the original string if the last character is not "/"
}
