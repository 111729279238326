import React from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Tooltip
} from "@mui/material";

const AddEditNote = ({ value, onNotesChanged }) => {
  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = React.useState(value);

  const handleClose = () => {
    setOpen(false);
    if (notes !== value) {
      onNotesChanged && onNotesChanged(notes);
    }
  };
  function onNoteClick() {
    setOpen(true);
  }

  function getDialog() {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Notes</DialogTitle>
        <DialogContent>
          <DialogContentText>Add archive notes</DialogContentText>
          <TextField
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            autoFocus
            margin="dense"
            id="note"
            label="Notes"
            fullWidth
            multiline
            variant="standard"
            value={notes}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <>
      {getDialog()}
      <Tooltip title={value}>
        <IconButton onClick={onNoteClick}>
          {!value ? <PostAddIcon /> : <EditNoteIcon />}
        </IconButton>
      </Tooltip>
    </>
  );
};
export default AddEditNote;
